<template>
  <div class="imgBox min-w-1400">
    <!-- <div class="imgBox1 min-w-1400" @click="toggleNewImage(1)">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div> -->
    <div class="imgBox2 min-w-1400" @click="toggleNewImage(2)">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400" @click="toggleNewImage(3)">
      <div class="img3 max-w-1400">
        <img
          :src="showNewImage3 ? AnswerImg3 : importedImg3"
          :alt="altText"
          class="imgItem3"
        />
      </div>
    </div>
    <div class="imgBox4 min-w-1400" @click="toggleNewImage(4)">
      <div class="img4 max-w-1400">
        <img
          :src="showNewImage4 ? AnswerImg4 : importedImg4"
          :alt="altText"
          class="imgItem4"
        />
      </div>
    </div>
    <div class="imgBox5 min-w-1400" @click="toggleNewImage(5)">
      <div class="img5 max-w-1400">
        <img
          :src="showNewImage5 ? AnswerImg5 : importedImg5"
          :alt="altText"
          class="imgItem5"
        />
      </div>
    </div>
    <div class="imgBox6 min-w-1400" @click="toggleNewImage(6)">
      <div class="img6 max-w-1400">
        <img
          :src="showNewImage6 ? AnswerImg6 : importedImg6"
          :alt="altText"
          class="imgItem6"
        />
      </div>
    </div>
    <div class="imgBox7 min-w-1400" @click="toggleNewImage(7)">
      <div class="img7 max-w-1400">
        <img
          :src="showNewImage7 ? AnswerImg7 : importedImg7"
          :alt="altText"
          class="imgItem7"
        />
      </div>
    </div>

    <div class="imgBox8 min-w-1400" @click="toggleNewImage(8)">
      <div class="img8 max-w-1400">
        <img
          :src="showNewImage8 ? AnswerImg8 : importedImg8"
          :alt="altText"
          class="imgItem8"
        />
      </div>
    </div>
    <div class="imgBox9 min-w-1400" @click="toggleNewImage(9)">
      <div class="img9 max-w-1400">
        <img
          :src="showNewImage9 ? AnswerImg9 : importedImg9"
          :alt="altText"
          class="imgItem9"
        />
      </div>
    </div>
    <div class="imgBox10 min-w-1400" @click="toggleNewImage(10)">
      <div class="img10 max-w-1400">
        <img
          :src="showNewImage10 ? AnswerImg10 : importedImg10"
          :alt="altText"
          class="imgItem10"
        />
      </div>
    </div>
    <div class="imgBox11 min-w-1400" @click="toggleNewImage(11)">
      <div class="img11 max-w-1400">
        <img
          :src="showNewImage11 ? AnswerImg11 : importedImg11"
          :alt="altText"
          class="imgItem11"
        />
      </div>
    </div>
    <div class="imgBox12 min-w-1400" @click="toggleNewImage(12)">
      <div class="img12 max-w-1400">
        <img
          :src="showNewImage12 ? AnswerImg12 : importedImg12"
          :alt="altText"
          class="imgItem12"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";

import importedImg2 from "@/page/onsenmobile/components/img/3_1.png";
import importedImg3 from "@/page/onsenmobile/components/img/3_3.png";
import importedImg4 from "@/page/onsenmobile/components/img/3_4.png";
import importedImg5 from "@/page/onsenmobile/components/img/3_5.png";
import importedImg6 from "@/page/onsenmobile/components/img/3_6.png";
import importedImg7 from "@/page/onsenmobile/components/img/3_7.png";
import importedImg8 from "@/page/onsenmobile/components/img/3_8.png";
import importedImg9 from "@/page/onsenmobile/components/img/3_9.png";
import importedImg10 from "@/page/onsenmobile/components/img/3_10.png";
import importedImg11 from "@/page/onsenmobile/components/img/3_11.png";
import importedImg12 from "@/page/onsenmobile/components/img/3_12.png";

import AnswerImg3 from "@/page/onsenmobile/components/img/3_3_3.png";
import AnswerImg4 from "@/page/onsenmobile/components/img/3_4_4.png";
import AnswerImg5 from "@/page/onsenmobile/components/img/3_5_5.png";
import AnswerImg6 from "@/page/onsenmobile/components/img/3_6_6.png";
import AnswerImg7 from "@/page/onsenmobile/components/img/3_7_7.png";
import AnswerImg8 from "@/page/onsenmobile/components/img/3_8_8.png";
import AnswerImg9 from "@/page/onsenmobile/components/img/3_9_9.png";
import AnswerImg10 from "@/page/onsenmobile/components/img/3_10_10.png";
import AnswerImg11 from "@/page/onsenmobile/components/img/3_11_11.png";
import AnswerImg12 from "@/page/onsenmobile/components/img/3_12_12.png";

export default {
  data() {
    return {
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
      importedImg8: importedImg8,
      importedImg9: importedImg9,
      importedImg10: importedImg10,
      importedImg11: importedImg11,
      importedImg12: importedImg12,

      AnswerImg3: AnswerImg3,
      AnswerImg4: AnswerImg4,
      AnswerImg5: AnswerImg5,
      AnswerImg6: AnswerImg6,
      AnswerImg7: AnswerImg7,
      AnswerImg8: AnswerImg8,
      AnswerImg9: AnswerImg9,
      AnswerImg10: AnswerImg10,
      AnswerImg11: AnswerImg11,
      AnswerImg12: AnswerImg12,

      altText: "图片",
      showNewImage2: false,
      showNewImage3: false,
      showNewImage4: false,
      showNewImage5: false,
      showNewImage6: false,
      showNewImage7: false,
      showNewImage8: false,
      showNewImage9: false,
      showNewImage10: false,
      showNewImage11: false,
      showNewImage12: false,
      showNewImage13: false,
      showNewImage14: false,
      activeImage: 0,
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    toggleNewImage(imageIndex) {
      //  console.log("点击了");
      //   this.$set(this.showNewImage, imageIndex, !this.showNewImage[imageIndex]);
      //   this.activeImage = imageIndex;
      console.log(this.showNewImage3);
      if (imageIndex == 2) {
        this.showNewImage2 = !this.showNewImage2;
      }
      if (imageIndex == 3) {
        this.showNewImage3 = !this.showNewImage3;
      }
      if (imageIndex == 4) {
        this.showNewImage4 = !this.showNewImage4;
      }
      if (imageIndex == 5) {
        this.showNewImage5 = !this.showNewImage5;
      }
      if (imageIndex == 6) {
        this.showNewImage6 = !this.showNewImage6;
      }
      if (imageIndex == 7) {
        this.showNewImage7 = !this.showNewImage7;
      }
      if (imageIndex == 8) {
        this.showNewImage8 = !this.showNewImage8;
      }
      if (imageIndex == 9) {
        this.showNewImage9 = !this.showNewImage9;
      }
      if (imageIndex == 10) {
        this.showNewImage10 = !this.showNewImage10;
      }
      if (imageIndex == 11) {
        this.showNewImage11 = !this.showNewImage11;
      }
      if (imageIndex == 12) {
        this.showNewImage12 = !this.showNewImage12;
      }
      if (imageIndex == 13) {
        this.showNewImage13 = !this.showNewImage13;
      }
      if (imageIndex == 14) {
        this.showNewImage14 = !this.showNewImage14;
      }
    },
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7,
.imgBox8,
.imgBox9,
.imgBox10,
.imgBox11,
.imgBox12,
.imgBox13,
.imgBox14 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}

.img3,
.img4,
.img5,
.img6,
.img7,
.img8,
.img9,
.img10,
.img11,
.img12,
.img13,
.img14 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.img2 {
  margin-left: 45px;
  margin-right: 45px;
}

.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10,
.imgItem11,
.imgItem12,
.imgItem13,
.imgItem14 {
  max-width: 100%;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10,
.imgItem11,
.imgItem12,
.imgItem13,
.imgItem14 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  margin-top: 20px;
}
.imgItem2 {
  margin-top: 50px;
}
.imgItem3 {
  margin-top: 80px;
}
.imgItem12 {
  margin-bottom: 150px;
}
</style>