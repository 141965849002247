<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="productImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="productImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>

  </div>
</template>

<script>
import productImg1 from "@/page/onsenmobile/components/img/4_1.png";
import productImg2 from "@/page/onsenmobile/components/img/4_2.png";

export default {
  
  data() {
    return {
      productImg1: productImg1,
      productImg2: productImg2,

      altText: "图片",
     
    };
  },
  created() {
    this.gotoTop();
    // this.getUrlParams();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    //     getUrlParams() {
    //   const url = window.location.href;
    //   let obj = {};
    //   try {
    //     let urlStr = url.split("?")[1];
    //     let paramsArr = urlStr.split("&");
    //     for (let i = 0, len = paramsArr.length; i < len; i++) {
    //       let arr = paramsArr[i].split("=");
    //       obj[arr[0]] = arr[1];
    //     }
    //   } catch (error) {
    //     console.error("An error occurred while parsing URL parameters:", error);
    //   }
    //   const pdfDom = document.getElementById("pdf-iframe");
    //   const docid = document.getElementById("docid");
    //   if (obj) {
    //     docid.textContent = obj.docid || "";
    //     pdfDom.setAttribute(
    //       "src",
    //       `./assets/${(obj.token || "").toLowerCase()}.pdf`
    //     );
    //   }
    // },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
.imgBox1 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 2900px;
  background-color: #f7f7f7;
  border-radius: 32px;
  margin-top: -130px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

img {
  display: block;
}
.img1 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.img2 {
  margin-left: 45px;
  margin-right: 45px;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.pdf-container {
  width: 100%;
}
</style>
