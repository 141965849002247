<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/onsenmobile/components/img/1_1.png";
import importedImg2 from "@/page/onsenmobile/components/img/1_2.png";
import importedImg3 from "@/page/onsenmobile/components/img/1_6.png";
export default {
  data() {
    return {
      importedImg1: importedImg1,

      importedImg2: importedImg2,
      importedImg3: importedImg3,
      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.imgBox3

 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox1,
.imgBox2 {
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
.img3{
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.img1,
.img2 {
  margin-left: 45px;
  margin-right: 45px;
}

.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem1,
.imgItem2 {
  max-width: 100%;
}
.imgItem1 {
  margin-top: 60px;
}
.imgItem2 {
  margin-top: 100px;
  margin-bottom: 150px;
}
.imgItem3{
  margin-top: 120px;
}
/* .imgItem3 {
  max-width: 100%;
  margin-bottom: 100px;
}
.imgItem4 {
margin-bottom: 100px;
} */
</style>
